/* eslint-disable react/jsx-no-target-blank */

/**
 * Downloads component
 * @returns {JSX.Element} Downloads component
 */
export default function Downloads() {
	/** BAR resources, in order that it will display in about section */
	const barResources = {
		"BAR API": {
			link: "//bar.utoronto.ca/api",
		},
	};

	return (
		<div className="downloads">
			<h3>Data Versions</h3>
			<p>
				See the About section, accessible via the three dots &#8285; at the top right of the BAR homepage, for
				the current data versions used in the BAR.
			</p>
			<hr />
			<h3>ePlants Data</h3>
			<p>
				Data can be downloaded from ePlants by clicking the &quot;Download Raw Data&quot; button in most ePlant
				views (top right).
			</p>
			<hr />
			<h3>BAR API</h3>
			<p>Many different types of data can be programmatically retrieved from the BAR using the:</p>
			<p>
				BAR API at{" "}
				<a href={barResources["BAR API"].link} target="_blank">
					https://bar.utoronto.ca/api
				</a>
			</p>
			<hr />
			<h3>Bulk Downloads</h3>
			<p>Several BAR datasets are available for bulk download.</p>
			<hr />
			<h3>Thalemine</h3>
			<p>
				Data can be exported using imtables. These tables are available via ThaleMine templates, lists and
				reports page. Bulk data can also be downloaded via ThaleMine API.
			</p>
			<hr />
			<h3>Feedback Form for Custom Requests</h3>
			<p>
				Other data sets can be requested using the feedback form on the BAR homepage, click the three dots
				&#8285; at the top right to access this.
			</p>
		</div>
	);
}
