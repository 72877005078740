import { Card, CardActionArea, CardContent, CardMedia, Grid } from "@mui/material";
import molecularMarkers from "../../../images/marketing/Process-map-icon-mustard-301x300.webp";
import geneExpression from "../../../images/marketing/functional-genomics-icon.webp";
import genomicTools from "../../../images/marketing/toolsIcon.webp";
import "./marketing.css";

/**
 * Marketing component
 * @returns {JSX.Element} Marketing component
 */
export default function Marketing() {
	/** Marketing items in order from left to right */
	const marketing = [
		{
			title: "Gene Expression and Protein Tools",
			description:
				"View expression patterns as electronic fluorescent pictographs or heatmaps, explore promoters, identify protein-protein interactions and more.",
			image: geneExpression,
			imageAlt: "Functional Genomics",
			scrollTo: "#GeneExpressionAndProteinTools",
		},
		{
			title: "Molecular Markers and Mapping Tools",
			description:
				"Perform Next Generation Mapping, or generate your own markers using our molecular marker tools.",
			image: molecularMarkers,
			imageAlt: "Molecular Markers",
			scrollTo: "#MolecularMarkersAndMappingTools",
		},
		{
			title: "Other Genomic Tools and Widgets",
			description:
				"Remove duplicates, perform multi-dimensional Venn analyses, or generate random lists of identifiers.",
			image: genomicTools,
			imageAlt: "Other Genomic Tools",
			scrollTo: "#OtherGenomicTools",
		},
	];

	return (
		<Grid container direction="row" justifyContent="space-around" alignItems="baseline">
			{marketing.map((item, index) => (
				// eslint-disable-next-line react/no-array-index-key
				<Grid item xs={12} md={4} key={index}>
					<Card className="marketing-cards">
						<CardActionArea href={item.scrollTo}>
							<CardMedia
								component="img"
								className="img-circle animated rotateIn"
								alt={item.imageAlt}
								title={item.imageAlt}
								image={item.image}
								loading="auto"
							/>
							<CardContent>
								<h2>{item.title}</h2>
								<p>{item.description}</p>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			))}
		</Grid>
	);
}
