import { Button } from "@mui/material";
import "./publications.css";

/**
 * Publications component
 * @returns {JSX.Element} Publications component
 */
export default function Publications() {
	/** Publication data, in order they will appear */
	const pubData = [
		{
			details:
				"Kulesza E, Thomas P, Prewitt SF, Shalit-Kaneh A, Wafula E, Knollenberg B, Winters N, Esteban E, Pasha A, Provart N, Praul C, Landherr L, dePamphilis C, Maximova SN, Guiltinan MJ (2024). The cacao gene atlas: a transcriptome developmental atlas reveals highly tissue-specific and dynamically-regulated gene networks in Theobroma cacao L. BMC Plant Biol. 24 (1), 601. (eFP Cacao)",
			link: "https://doi.org/10.1186/s12870-024-05171-9",
		},
		{
			details:
				"Luna-García V, Bernal Gallardo JJ, Rethoret-Pasty M, Pasha A, Provart NJ, de Folter S (2024). A high-resolution gene expression map of the medial and lateral domains of the gynoecium of Arabidopsis. Plant Physiol. 195:410-429. (Arabidopsis eFP Browser)",
			link: "https://doi.org/10.1093/plphys/kiad658",
		},
		{
			details:
				"Fan C, Lyu M, Zeng B, He Q, Wang X, Lu MZ, Liu B, Liu J, Esteban E, Pasha A, Provart NJ, Wang H, Zhang J (2024). Profiling of the gene expression and alternative splicing landscapes of Eucalyptus grandis. Plant Cell Environ. 47:1363-1378. (ePlant Eucalyptus)",
			link: "https://doi.org/10.1111/pce.14814",
		},
		{
			details:
				"Kovacik M, Nowicka A, Zwyrtková J, Strejčková B, Vardanega I, Esteban E, Pasha A, Kaduchová K, Krautsova M, Červenková M, Šafář J, Provart NJ, Simon R, Pecinka A (2024). The transcriptome landscape of developing barley seeds. Plant Cell 36:2512-2530. (ePlant Barley)",
			link: "https://doi.org/10.1093/plcell/koae095",
		},
		{
			details:
				"Schnabel E, Thomas J, El-Hawaz R, Gao Y, Poehlman WL, Chavan S, Pasha A, Esteban E, Provart N, Feltus FA, Frugoli J (2023). Laser Capture Microdissection Transcriptome Reveals Spatiotemporal Tissue Gene Expression Patterns of Medicago truncatula Roots Responding to Rhizobia. Mol Plant Microbe Interact. 36:805-820. (ePlant Medicago)",
			link: "https://doi.org/10.1094/MPMI-03-23-0029-R",
		},
		{
			details:
				"Da Ros L, Bollina V, Soolanayakanahally R, Pahari S, Elferjani R, Kulkarni M, Vaid N, Risseuw E, Cram D, Pasha A, Esteban E, Konkin D, Provart N, Nambara E and Kagale S (2023), Multi-omics atlas of combinatorial abiotic stress responses in wheat. Plant J. 116 (4), 1118-1135. (eFP Wheat)",
			link: "https://doi.org/10.1111/tpj.16332",
		},
		{
			details:
				"Jiang Y, N'Diaye A, Koh CS, Quilichini TD, Shunmugam ASK, Kirzinger MW, Konkin D, Bekkaoui Y, Sari E, Pasha A, Esteban E, Provart NJ, Higgins JD, Rozwadowski K, Sharpe AG, Pozniak CJ, Kagale S (2023). The coordinated regulation of early meiotic stages is dominated by non-coding RNAs and stage-specific transcription in wheat. Plant J. 114: 209-224. (eFP Wheat)",
			link: "https://doi.org/10.1111/tpj.16125",
		},
		{
			details:
				"Mahood EH, Bennett AA, Komatsu K, Kruse LH, Lau V, Rahmati Ishka M, Jiang Y, Bravo A, Louie K, Bowen BP, Harrison MJ, Provart NJ, Vatamaniuk OK, Moghe GD (2023). Information theory and machine learning illuminate large-scale metabolomic responses of Brachypodium distachyon to environmental change. Plant J. 114(3): 463-481 (eFP Brachypodium Metabolites)",
			link: "https://doi.org/10.1111/tpj.16160",
		},
		{
			details:
				"Johnson AR, Yue Y, Carey SB, Park SJ, Kruse LH, Bao A, Pasha A, Harkess A, Provart NJ, Moghe GD, Frank MH (2023). Chromosome-level Genome Assembly of Euphorbia peplus, a Model System for Plant Latex, Reveals that Relative Lack of Ty3 Transposons Contributed to Its Small Genome Size. Genome Biol Evol. 15(3):evad018. (eFP Euphorbia)",
			link: "https://doi.org/10.1093/gbe/evad018",
		},
		{
			details:
				"Tan QW, Lim PK, Chen Z, Pasha A, Provart N, Arend M, Nikoloski Z, Mutwil M (2023). Cross-stress gene expression atlas of Marchantia polymorpha reveals the hierarchy and regulatory principles of abiotic stress responses. Nat Commun. 14: 986. (eFP Marchantia)",
			link: "https://doi.org/10.1038/s41467-023-36517-w",
		},
		{
			details:
				"Hosaka GK, Correr FH, da Silva CC, Sforça DA, Barreto FZ, Balsalobre TWA, Pasha A, de Souza AP, Provart NJ, Carneiro MS, Margarido GRA (2022). Temporal Gene Expression in Apical Culms Shows Early Changes in Cell Wall Biosynthesis Genes in Sugarcane. Frontiers in Plant Sci. 12:736797. (Sugarcane ePlant)",
			link: "https://doi.org/10.3389/fpls.2021.736797",
		},
		{
			details:
				"Gao P, Quilichini TD, Yang H, Li Q, Nilsen KT, Qin L, Babic V, Liu L, Cram D, Pasha A, Esteban E, Condie J, Sidebottom C, Zhang Y, Huang Y, Zhang W, Bhowmik P, Kochian LV, Konkin D, Wei Y, Provart NJ, Kagale S, Smith M, Patterson N, Gillmor CS, Datla R, Xiang D (2022). Evolutionary divergence in embryo and seed coat development of U's Triangle Brassica species illustrated by a spatiotemporal transcriptome atlas. New Phytol. 233:30-51. (Brassica eFP Browser)",
			link: "https://doi.org/10.1111/nph.17759",
		},
		{
			details:
				"Kajala K, Gouran M, Shaar-Moshe L, Mason GA, Rodriguez-Medina J, Kawa D, Pauluzzi G, Reynoso M, Canto-Pastor A, Manzano C, Lau V, Artur MAS, West DA, Gray SB, Borowsky AT, Moore BP, Yao AI, Morimoto KW, Bajic M, Formentin E, Nirmal N, Rodriguez A, Pasha A, Deal RB, Kleibenstein D, Hvidsten TR, Provart NJ, Sinha N, Runciel DE, Bailey-Serres J, Brady SM (2021). Innovation, conservation and repurposing of gene function in plant root cell type development. Cell 184:5070. (Tomato Root eFP Views)",
			link: "https://doi.org/10.1016/j.cell.2021.08.032",
		},
		{
			details:
				"Thiel J, Koppolu R, Trautewig C, Hertig C, Kale SM, Erbe S, Mascher M, Himmelbach A, Rutten T, Esteban E, Pasha A, Kumlehn J, Provart NJ, Vanderauwera S, Frohberg C, Schnurbusch T (2021). Transcriptional landscapes of floral meristems in barley. Science Advances 7: eabf0832. (Barley Floral Meristem eFP View)",
			link: "https://doi.org/10.1126/sciadv.abf0832",
		},
		{
			details:
				"Kehelpannala C, Rupasinghe T, Pasha A, Esteban E, Hennessy T, Bradley D, Ebert B, Provart NJ, Roessner U. (2021). An Arabidopsis lipid map reveals differences between tissues and dynamic changes throughout development. Plant J. (epub ahead of print). (Lipid eFP Browser)",
			link: "https://doi.org/10.1111/tpj.15278",
		},
		{
			details:
				"Bizouerne E, Buitink J, Vu BL, Vu JL, Esteban E, Pasha A, Provart N, Verdier J, Leprince O (2021). Gene co-expression analysis of tomato seed maturation reveals tissue-specific regulatory networks and hubs associated with the acquisition of desiccation tolerance and seed vigour. BMC Plant Biol. 21:124. (Tomato Seed eFP View)",
			link: "https://doi.org/10.1186/s12870-021-02889-8",
		},
		{
			details:
				"Brian L, Warren B, McAtee P, Rodrigues J, Nieuwenhuizen N, Pasha A, David KM, Richardson A, Provart NJ, Allan AC, Varkonyi-Gasic E, Schaffer RJ (2021). A gene expression atlas for kiwifruit (Actinidia chinensis) and network analysis of transcription factors. BMC Plant Biol. 21:121. (Kiwi eFP Browser)",
			link: "https://doi.org/10.1186/s12870-021-02894-x",
		},
		{
			details:
				"Pasha A, Subramaniam S, Cleary A, Chen X, Berardini T, Farmer A, Town C, Provart N (2020). Araport Lives: An Updated Framework for Arabidopsis Bioinformatics. Plant Cell 32:2683-2686. (ThaleMine)",
			link: "https://doi.org/10.1105/tpc.20.00358",
		},
		{
			details:
				"Rich-Griffin C, Eichmann R, Reitz MU, Hermann S, Woolley-Allen K, Brown PE, Wiwatdirekkul K, Esteban E, Pasha A, Kogel KH, Provart NJ, Ott S, Schäfer P (2020). Regulation of Cell Type-Specific Immunity Networks in Arabidopsis Roots. Plant Cell 32:2742-2762. (Root Immunity eFP Browser)",
			link: "https://doi.org/10.1105/tpc.20.00154",
		},
		{
			details:
				"Zhang L, Liu M, Long H, Dong W, Pasha A, Esteban E, Li W, Yang X, Li Z, Song A, Ran D, Zhao G, Zeng Y, Chen H, Zou M, Li J, Liang F, Xie M, Hu J, Wang D, Cao H, Provart NJ, Zhang L, Tan X (2020) Tung Tree (Vernicia fordii) Genome Provides A Resource for Understanding Genome Evolution and Improved Oil Production. Genomics Proteomics Bioinformatics. 17: 558-575. (eFP Tung Tree)",
			link: "https://doi.org/10.1016/j.gpb.2019.03.006",
		},
		{
			details:
				"Zhang J, Hu R, Sreedasyam A, Garcia TM, Lipzen A, Wang M, Yerramsetty P, Liu D, Ng V, Schmutz J, Cushman JC, Borland AM, Pasha A, Provart NJ, Chen JG, Muchero W, Tuskan GA, Yang X (2020). Light-responsive expression atlas reveals the effects of light quality and intensity in Kalanchoë fedtschenkoi, a plant with crassulacean acid metabolism. GigaScience 9: giaa018. (eFP Kalanchoë)",
			link: "https://doi.org/10.1093/gigascience/giaa018",
		},
		{
			details:
				"Doll NM, Just J, Brunaud V, Caïus J, Grimault A, Depège-Fargeix N, Esteban E, Pasha A, Provart NJ, Ingram GC, Rogowsky PM, and Widiez T (2020). Transcriptomics at Maize Embryo/Endosperm Interfaces Identifies a Transcriptionally Distinct Endosperm Subdomain Adjacent to the Embryo Scutellum. Plant Cell 32: 833-852. (eFP Maize)",
			link: "https://doi.org/10.1105/tpc.19.00756",
		},
		{
			details:
				"Ferrari C, Shivhare D, Hansen BO, Pasha A, Esteban E, Provart NJ, Kragler F, Fernie A, Tohge T, and Mutwil M (2020). Expression Atlas of Selaginella moellendorffii Provides Insights into the Evolution of Vasculature, Secondary Metabolism, and Roots. Plant Cell 32: 853-870. (eFP Selaginella)",
			link: "https://doi.org/10.1105/tpc.19.00780",
		},
		{
			details:
				"Hoopes GM, Hamilton JP, Wood JC, Esteban E, Pasha A, Vaillancourt B, Provart NJ, Buell CR (2019). An Updated Gene Atlas for Maize Reveals Organ-Specific and Stress-Induced Genes. Plant J. 97:1154-1167. (eFP Maize)",
			link: "https://doi.org/10.1111/tpj.14184",
		},
		{
			details:
				"Mott GA, Smakowska-Luzan E, Pasha A, Parys K, Howton TC, Neuhold J, Lehner A, Grünwald K, Stolt-Bergner P, Provart NJ, Mukhtar MS, Desveaux D, Guttman DS, Belkhadir Y (2019). Map of physical interactions between extracellular domains of Arabidopsis leucine-rich repeat receptor kinases. Sci. Data. 6:190025. (AIV)",
			link: "https://doi.org/10.1038/sdata.2019.25",
		},
		{
			details:
				"Tian C, Wang Y, Yu H, He J, Wang J, Shi B, Du Q, Provart NJ, Meyerowitz EM, Jiao Y (2019). A gene expression map of shoot domains reveals regulatory mechanisms. Nature Commun. 10:141. (Arabidopsis Shoot eFP View)",
			link: "https://doi.org/10.1038/s41467-018-08083-z",
		},
		{
			details:
				"Dong S, Lau V, Song R, Ierullo M, Esteban E, Wu Y, Sivieng T, Nahal H, Gaudinier A, Pasha A, Oughtred R, Dolinski K, Tyers M, Brady SM, Grene R, Usadel B, Provart NJ (2019). Proteome-wide, Structure-Based Prediction of Protein-Protein Interactions/New Molecular Interactions Viewer. Plant Physiol. 179:1893-1907. doi: 10.1104/pp.18.01216. (AIV2)",
			link: "https://doi.org/10.1104/pp.18.01216",
		},
		{
			details:
				"Xiang D, Quilichini TD, Liu Z, Gao P, Pan Y, Li Q, Nilsen KT, Venglat P, Esteban E, Pasha A, Wang Y, Wen R, Zhang Z, Hao Z, Wang E, Wei Y, Cuthbert R, Kochian LV, Sharpe A, Provart N, Weijers D, Gillmor CS, Pozniak C, Datla R (2019). The Transcriptional Landscape of Polyploid Wheats and Their Diploid Ancestors during Embryogenesis and Grain Development. Plant Cell 31: 2888-2911. (eFP Wheat)",
			link: "https://doi.org/10.1105/tpc.19.00397",
		},
		{
			details:
				"Sullivan A, Purohit PK, Freese NH, Pasha A, Esteban E, Waese J, Wu A, Chen M, Chin CY, Song R, Watharkar SR, Chan AP, Krishnakumar V, Vaughn MW, Town C, Loraine AE, Provart NJ (2019). An ‘eFP-Seq Browser’ for visualizing and exploring RNA sequencing data. Plant J. 100: 641–654. (eFP-Seq Browser)",
			link: "https://doi.org/10.1111/tpj.14468",
		},
		{
			details:
				"Yi F, Gu W, Chen J, Song N, Gao X, Zhang X, Zhou Y, Ma X, Song W, Zhao H, Esteban E, Pasha A, Provart NJ, Lai J (2019). High Temporal-Resolution Transcriptome Landscape of Early Maize Seed Development. Plant Cell. 31: 974-992. (eFP Maize)",
			link: "https://doi.org/10.1105/tpc.18.00961",
		},
		{
			details:
				"Smakowska-Luzan E, Mott GA, Parys K, Stegmann M, Howton TC, Layeghifard M, Neuhold J, Lehner A, Kong J, Grünwald K, Weinberger N, Satbhai SB, Mayer D, Busch W, Madalinski M, Stolt-Bergner P, Provart NJ, Mukhtar MS, Zipfel C, Desveaux D, Guttman DS, Belkhadir Y (2018). An extracellular network of Arabidopsis leucine-rich repeat receptor kinases. Nature 553: 342-346. (AIV)",
			link: "https://doi.org/10.1038/nature25184",
		},
		{
			details:
				"Ramírez-González RH, Borrill P, Lang D, Harrington SA, Brinton J, Venturini L, Davey M, Jacobs J, van Ex F, Pasha A, Khedikar Y, Robinson SJ, Cory AT, Florio T, Concia L, Juery C, Schoonbeek H, Steuernagel B, Xiang D, Ridout CJ, Chalhoub B, Mayer KFX, Benhamed M, Latrasse D, Bendahmane A, International Wheat Genome Sequencing Consortium, Wulff BBH, Appels R, Tiwari V, Datla R, Choulet F, Pozniak CJ, Provart NJ, Sharpe AG, Paux E, Spannagl M, Bräutigam A, Uauy C (2018). The transcriptional landscape of polyploid wheat. Science 361: 662. (eFP Wheat)",
			link: "https://doi.org/10.1126/science.aar6089",
		},
		{
			details:
				"Toal TW, Ron M, Gibson D, Kajala K, Splitt B, Johnson LS, Miller ND, Slovak R, Gaudinier A, Patel R, de Lucas M, Provart NJ, Spalding EP, Busch W, Kliebenstein DJ, Brady SM (2018). Regulation of Root Angle and Gravitropism. Genes, Genomes and Genetics (G3) 8: 3841-3855. (Tomato Root Angle QTL eFP View).",
			link: "https://doi.org/10.1534/g3.118.200540",
		},
		{
			details:
				"Herrbach V, Chirinos X, Rengel D, Agbevenou K, Vincent R, Pateyron S, Huguet S, Balzergue S, Pasha A, Provart N, Gough C, Bensmihen S (2017). Nod factors potentiate auxin signaling for transcriptional regulation and lateral root formation in Medicago truncatula. Journal of Experimental Botany 68(3): 569-583. (Medicago CSV).",
			link: "https://dx.doi.org/10.1093/jxb/erw474",
		},
		{
			details:
				"Hey S, Baldauf J, Opitz N, Lithio A, Pasha A, Provart N, Nettleton D, Hochholdinger F (2017). Complexity and specificity of the maize (Zea mays L.) root hair transcriptome. Journal of Experimental Botany 68(9):2175-2185. (eFP Maize Root view).",
			link: "https://doi.org/10.1093/jxb/erx104",
		},
		{
			details:
				"Waese J, Provart NJ, Guttman DS (2017). Topo-phylogeny: Visualizing evolutionary relationships on a topographic landscape. PLoS One 12(5):e0175895. (Topo-phylogeny).",
			link: "https://doi.org/10.1371/journal.pone.0175895",
		},
		{
			details:
				"Sibout R, Proost S, Hansen BO, Vaid N, Giorgi FM, Ho-Yue-Kuang S, Legée F, Cézart L, Bouchabké-Coussa O, Soulhat C, Provart N, Pasha A, Le Bris P, Roujol D, Hofte H, Jamet E, Lapierre C, Persson S, Mutwil M (2017). Expression atlas and comparative coexpression network analyses reveal important genes involved in the formation of lignified cell wall in Brachypodium distachyon. New Phytologist 215(3):1009-1025. (Brachypodium eFP Browser).",
			link: "https://dx.doi.org/10.1111/nph.14635",
		},
		{
			details:
				"Waese J, Fan J, Pasha A, Yu H, Fucile G, Shi R, Cumming M, Kelley L, Sternberg M, Krishnakumar V, Ferlanti E, Miller J, Town C, Stuerzlinger W, Provart NJ (2017). ePlant: Visualizing and Exploring Multiple Levels of Data for Hypothesis Generation in Plant Biology. Plant Cell Aug;29(8):1806-1821. (New ePlant).",
			link: "https://doi.org/10.1105/tpc.17.00073",
		},
		{
			details:
				"Provart NJ, Alonso J, Assmann SM, Bergmann D, Brady SM, Brkljacic J, Browse J, Chapple C, Colot V, Cutler S, Dangl J, Ehrhardt D, Friesner JD, Frommer WB, Grotewold E, Meyerowitz E, Nemhauser J, Nordborg M, Pikaard C, Shanklin J, Somerville C, Stitt M, Torii KU, Waese J, Wagner D, McCourt P (2016). 50 years of Arabidopsis research: highlights and future directions. New Phylologist 209: 921-944. (Arabidopsis Citation Network Viewer).",
			link: "https://dx.doi.org/10.1111/nph.13687",
		},
		{
			details:
				'Patel RV, Hamanishi ET, Provart NJ (2016). A Human" eFP" Browser for Generating Gene Expression Anatograms. PloS one 11(3): e0150982. (Human eFP Browser).',
			link: "https://dx.doi.org/10.1371/journal.pone.0150982",
		},
		{
			details:
				"Austin RS, Hiu S, Waese J, Ierullo M, Pasha A, Wang TT, Fan J, Foong C, Breit R, Desveaux D, Moses A, Provart NJ (2016). New BAR tools for mining expression data and exploring Cis-elements in Arabidopsis thaliana. The Plant Journal 8(3):490-504. (Cistome and Expression Angler).",
			link: "https://dx.doi.org/10.1111/tpj.13261",
		},
		{
			details:
				"Waese J, Pasha A, Wang TT, van Weringh A, Guttman DS, Provart NJ (2016). Gene Slider: sequence logo interactive data-visualization for education and research. Bioinformatics 32(23):3670-3672. (Gene Slider).",
			link: "https://dx.doi.org/10.1093/bioinformatics/btw525",
		},
		{
			details:
				"Kagale S, Nixon J, Khedikar Y, Pasha A, Provart NJ, Clarke WE, Bollina V, Robinson SJ, Coutu C, Hegedus DD, Sharpe AG, Parkin IA (2016). The developmental transcriptome atlas of the biofuel crop Camelina sativa. The Plant Journal 88(5):879-894. (Camelina eFP Browser).",
			link: "https://dx.doi.org/10.1111/tpj.13302",
		},
		{
			details:
				"Righetti K, Vu JL, Pelletier S, Vu BL, Glaab E, Lalanne D, Pasha A, Patel RV, Provart NJ, Verdier J, Leprince O, Buitink J (2015). Inference of Longevity-Related Genes from a Robust Coexpression Network of Seed Maturation Identifies Regulators Linking Seed Storability to Biotic Defense-Related Pathways. Plant Cell 27(10): 2692-708. (Medicago eFP Browser - Seed View).",
			link: "https://dx.doi.org/10.1105/tpc.15.00632",
		},
		{
			details:
				"Champigny MJ, Sung WWL, Catana V, Salwan R, Summers PS, Dudley SA, Provart NJ, Cameron RK, Golding GB, Weretilnyk EA (2013). RNA-Seq effectively monitors gene expression in Eutrema salsugineum plants growing in an extreme natural habitat and in controlled growth cabinet conditions. BMC Genomics 14:578. (E. salsugineum eFP Browser).",
			link: "https://dx.doi.org/10.1186/1471-2164-14-578",
		},
		{
			details:
				"Tran F, Penniket C, Patel RV, Provart NJ, Laroche A, Rowland O, Robert L (2013). Developmental transcriptional profiling reveals key regulators of Triticeae reproductive development. The Plant Journal 74:971-988. (Triticale eFP Browser).",
			link: "https://dx.doi.org/10.1111/tpj.12206",
		},
		{
			details:
				"Wang L, Czedik-Eysenberg A, Mertz RA, Si Y, Tohge T, Nunes-Nesi A, Dedow LK, Bryant D, Zhou W, Xu J, Weissmann S, Studer A, Li P, Zhang C, LaRue T, Shao Y, Sun Q, Patel RV, Turgeon R, Zhu X, Provart NJ, Mockler T, Fernie AR, Stitt M, Peng L, Brutnell TP (2013). Exploring the mechanism of C4 photosynthetic differentiation through a unified comparative analysis of maize and rice leaf transcriptomes. Nature Biotechnology 32: 1158-1165. (Maize eFP Browser - Maize and Rice Leaf Gradients View).",
			link: "https://dx.doi.org/10.1038/nbt.3019",
		},
		{
			details:
				"Chatfield SP, Capron R, Severino A, Penttila P, Alfred S, Nahal H, Provart NJ (2012). Incipient stem cell niche conversion in tissue culture: Using a systems approach to probe early events in WUSCHEL-dependent conversion of lateral root primordia into shoot meristems. The Plant Journal. 73:798-813. (Arabidopsis eFP Browser - Regeneration View).",
			link: "https://dx.doi.org/10.1111/tpj.12085",
		},
		{
			details:
				"Ho C-L, Wu Y, Shen H-B, Provart NJ, Geisler M (2012). A predicted protein interactome for rice. Rice. 5:15. (Rice Interactions Viewer).",
			link: "https://dx.doi.org/10.1186/1939-8433-5-15",
		},
		{
			details:
				"Patel RV, Nahal HK, Breit R, Chen Y, Provart NJ (2012). BAR Expressolog identification: expression profile ranking of “orthologous” genes in plant species. The Plant Journal 71:1038–1050. (Expressolog Tree Viewer).",
			link: "https://dx.doi.org/10.1111/j.1365-313X.2012.05055.x",
		},
		{
			details:
				"Austin RA, Vidaurre D, Stamatiou G, Breit R, Provart NJ, Bonetta D, Zhang J, Fung P, Gong Y, Wang PW, McCourt P, Guttman DS (2011). Application of next-generation mapping to identify genes involved in cell wall synthesis in Arabidopsis. The Plant Journal 67:715-725. (Next Generation Mapping).",
			link: "https://dx.doi.org/10.1111/j.1365-313X.2011.04619.x",
		},
		{
			details:
				"Bassel GW, Lan H, Glaab E, Gibbs DJ, Gerjets T, Krasnogor N, Bonner AJ, Holdsworth MJ, Provart NJ (2011). A genome-wide network model capturing seed germination reveals coordinated regulation of plant cellular phase transitions. Proceedings of the National Academy of Sciences of the U.S.A. 108:9709-9714. (SeedNet).",
			link: "https://dx.doi.org/10.1073/pnas.1100958108",
		},
		{
			details:
				"Dean G, Cao Y, Xiang D, Provart NJ, Ramsay L, Ahad A, White R, Selvaraj G, Datla R, Haughn G (2011). Analysis of gene expression patterns during seed coat development in Arabidopsis. Molecular Plant 4:1074-1092. (Arabidopsis Seed Coat eFP Browser).",
			link: "https://dx.doi.org/10.1093/mp/ssr040",
		},
		{
			details:
				"Fucile G, DiBiase D, Nahal H, La G, Khodabandeh S, Chen Y, Easley K, Christendat D, Kelley L, Provart NJ (2011). ePlant and the 3D Data Display Initiative: Integrative Systems Biology on the World Wide Web. PLoS ONE 6: e15237. (Original ePlant).",
			link: "https://dx.doi.org/10.1371/journal.pone.0015237",
		},
		{
			details:
				"Li P, Ponnala L, Gandotra N, Wang L, Si Y, Tausta L, Kebrom T, Provart N, Patel R, Myers C, Reidel E, Turgeon R, Liu P, Sun Q, Nelson T, Brutnell T (2010). The developmental dynamics of the maize leaf transcriptome. Nature Genetics 42: 1060-1067. (Maize eFP Browser - iPlant View).",
			link: "https://dx.doi.org/10.1038/ng.703",
		},
		{
			details:
				"Wilkins O, Waldron L, Nahal H, Provart NJ, Campbell MM (2009). Genotype and time of day shape the Populus drought response. The Plant Journal 60: 703-715. (Poplar eFP Browser - Poplar Treatment View).",
			link: "https://dx.doi.org/10.1111/j.1365-313X.2009.03993.x",
		},
		{
			details:
				"Wilkins O, Nahal H, Foong J, Provart NJ, Campbell MM (2009). Expansion and Diversification of the Populus R2R3-MYB Family of Transcription Factors. Plant Physiology 149(2), 981-993. ​ (Poplar eFP Browser - Poplar Developmental Series View).",
			link: "https://dx.doi.org/10.%E2%80%8B1104/%E2%80%8Bpp.%E2%80%8B108.%E2%80%8B132795",
		},
		{
			details:
				"Bassel GW, Fung P, Chow TF, Foong JA, Provart NJ, Cutler SR (2008). Elucidating the germination transcriptional program using small molecules. Plant Physiology 147: 143-155. (Arabidopsis eFP Browser - Seed View).",
			link: "https://dx.doi.org/10.%E2%80%8B1104/%E2%80%8Bpp.%E2%80%8B107.%E2%80%8B110841",
		},
		{
			details:
				"Geisler-Lee J, O'Toole N, Ammar R, Provart NJ, Millar AH, and Geisler M (2007). A Predicted Interactome for Arabidopsis thaliana. Plant Physiology 145:317–329. (Arabidopsis Interactions Viewer).",
			link: "https://dx.doi.org/10.%E2%80%8B1104/%E2%80%8Bpp.%E2%80%8B107.%E2%80%8B103465",
		},
		{
			details:
				"Winter D, Vinegar B, Ammar R, Nahal H, Wilson GV, Provart N (2007). An 'Electronic Fluorescent Pictograph' Browser for Exploring and Analyzing Large-Scale Biological Data Sets. PLoS ONE 2(8) : e718. (Arabidopsis eFP Browser, Cell eFP Browser, Mouse eFP Browser). * use Google Scholar with the keywords efpWeb.cgi -Winter to obtain number of URL citations.",
			link: "https://dx.doi.org/10.1371/journal.pone.0000718",
		},
		{
			details:
				"Geisler-Lee J, O'Toole N, Ammar R, Provart NJ, Millar AH, and Geisler M (2007). A Predicted Interactome for Arabidopsis thaliana. Plant Physiology 145:317–329. (Arabidopsis Interactions Viewer).",
			link: "https://dx.doi.org/10.%E2%80%8B1104/%E2%80%8Bpp.%E2%80%8B107.%E2%80%8B103465",
		},
		{
			details:
				"Taylor J, Provart NJ (2006). CapsID: A Web-based Tool for Developing Parsimonious Sets of CAPS Markers for Genotyping. BMC Genetics 7:27. (CapsID).",
			link: "https://dx.doi.org/10.1186/1471-2156-7-27",
		},
		{
			details:
				"Toufighi K, Brady S, Austin R, Ly E, Provart NJ (2005). The Bio-Analytic Resource: e-Northerns, Expression Angling, and Promoter Analyses. The Plant Journal 43: 153-163. (original Bio-Analytic Resource paper describing e-Northern tool, Expression Angler, and Promomer).",
			link: "https://dx.doi.org/10.1111/j.1365-313X.2005.02437.x",
		},
		{
			details:
				"Ilic K, Berleth T, Provart NJ (2004). BlastDigester - a web-based program for efficient CAPS marker design. TRENDS in Genetics 20(7):280-283. (BlastDigester).",
			link: "https://dx.doi.org/10.1016/j.tig.2004.04.012",
		},
		{
			details:
				"Provart N, Zhu T (2003). A Browser-based Functional Classification SuperViewer for Arabidopsis Genomics. Currents in Computational Molecular Biology 2003:271-272. (Classification SuperViewer).",
			link: "https://www.csb.utoronto.ca/wp-content/uploads/publications/ProvartZhu2003CurrCompMolBiol2003-271.pdf",
		},
	];

	return (
		<div className="publications">
			{pubData.map((pub, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<div key={i}>
					<Button
						href={pub.link}
						variant="outlined"
						className="getIt"
						target="_blank"
						rel="noopener noreferrer"
					>
						Get it!
					</Button>
					<p className="citation">{pub.details}</p>
				</div>
			))}
		</div>
	);
}
